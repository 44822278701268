import { NumberFormat } from '../NumberFormat';

export class CalculoMargem {
  public static async getValSugestaoVenda(
    custo_rep: number,
    custo_s_imposto: number,
    val_margem: number,
    per_desp_op: number,
    icms: number,
    tipo_margem: number,
    cod_preco: number,
    per_fcp_icms: number,
    pis_loja: number,
    cofins_loja: number,
    flg_nao_pis_cofins: boolean,
    precos: any[],
  ): Promise<number> {
    custo_rep = custo_rep === undefined ? 0 : custo_rep;
    custo_s_imposto = custo_s_imposto === undefined ? 0 : custo_s_imposto;
    val_margem = val_margem === undefined ? 0 : val_margem;
    per_desp_op = per_desp_op === undefined ? 0 : per_desp_op;
    icms = icms === undefined ? 0 : icms;
    tipo_margem = tipo_margem === undefined ? 0 : tipo_margem;
    cod_preco = cod_preco === undefined ? 0 : cod_preco;
    per_fcp_icms = per_fcp_icms === undefined ? 0 : per_fcp_icms;
    pis_loja = pis_loja === undefined ? 0 : pis_loja;
    cofins_loja = cofins_loja === undefined ? 0 : cofins_loja;

    let custo: number;
    let encargos: number;
    let valor: number;
    let result = 0.0;

    if (tipo_margem === 3) per_desp_op = 0;

    if (flg_nao_pis_cofins) encargos = icms + per_desp_op + per_fcp_icms;
    else encargos = icms + pis_loja + cofins_loja + per_desp_op + per_fcp_icms;

    custo = 0;

    switch (tipo_margem) {
      case 0:
        result = custo_rep * (1 + val_margem / 100);
        break;

      case 1:
        valor = 1 - encargos / 100 - val_margem / 100;
        result = custo_s_imposto / (valor === 0 ? 1 : valor);

        break;
      case 2:
        custo = NumberFormat.fltRound(custo_rep, 3);
        result = custo / (1 - val_margem / 100);
        break;
      case 3:
        result = custo_s_imposto / (1 - encargos / 100 - val_margem / 100);
        break;
      default:
        throw new Error(`Unsupported tipo_margem value: ${tipo_margem}`);
    }

    if (!Number.isInteger(result)) {
      result = await NumberFormat.AplicaRegraArredondamento(
        cod_preco,
        result,
        precos,
      );
    }

    result = NumberFormat.fltRound(result, 2);
    if (result < custo_rep) return custo_rep;
    return result;
  }

  public static getValMargemVenda(
    tipo_margem: number,
    val_custo_rep: number,
    val_custo_sem_imp: number,
    val_venda: number,
    val_imp_deb: number,
    per_desp_op: number,
  ): any {
    let result = 0.0;
    let val_custo = 0;
    let val_lucro_liq = 0;
    let val_des_op = 0;

    switch (tipo_margem) {
      case 0:
      case 2:
        val_custo = NumberFormat.fltRound(val_custo_rep, 2);
        break;

      case 1:
      case 3:
        val_custo = NumberFormat.fltRound(val_custo_sem_imp, 3);
        break;

      default:
        val_custo = 0;
        break;
    }

    val_custo_sem_imp = NumberFormat.fltRound(val_custo_sem_imp, 3);
    val_custo = NumberFormat.fltRound(val_custo, 3);
    val_venda = NumberFormat.fltRound(val_venda, 2);
    val_des_op = val_venda * (per_desp_op / 100);

    if (val_venda > 0.0 && val_custo > 0.0) {
      switch (tipo_margem) {
        case 0:
          result = (this.calcLucro(val_custo, val_venda) / val_custo) * 100;
          break;
        case 1:
          val_lucro_liq = this.getValLucroLiquido(
            val_custo_sem_imp,
            val_venda,
            val_imp_deb,
            val_des_op,
          );

          result = (val_lucro_liq / val_venda) * 100;

          break;

        case 2:
          result = (this.calcLucro(val_custo, val_venda) / val_venda) * 100;
          break;

        case 3:
          val_lucro_liq = this.getValLucroLiquido(
            val_custo_sem_imp,
            val_venda,
            val_imp_deb,
            0.0,
          );
          result = (val_lucro_liq / val_venda) * 100;
          break;

        default:
          throw new Error(`Unsupported tipo_margem value: ${tipo_margem}`);
      }
    }

    return NumberFormat.fltRound(result, 2);
  }

  public static getValLucroLiquido(
    ValCustoSemImp: number,
    ValVenda: number,
    ValImpDeb: number,
    ValDespOp: number,
  ): number {
    ValCustoSemImp = NumberFormat.fltRound(ValCustoSemImp, 3);

    let Result = ValVenda - ValCustoSemImp - (ValImpDeb + ValDespOp);

    Result = NumberFormat.fltRound(Result, 4);

    return Result;
  }

  private static calcLucro(custo: number, venda: number): number {
    const newCusto = NumberFormat.fltRound(custo, 2);
    const result = venda - newCusto;
    return NumberFormat.fltRound(result, 2);
  }
}
