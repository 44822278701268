export function createDateWithoutTimezone(dateString: string): Date {
  const dataSplit = dateString.split('T')[0];
  const [year, month, day] = dataSplit.split('-').map(Number);
  // Use Date.UTC para criar uma data no horário UTC e evitar o ajuste de fuso horário
  const utcDate = new Date(Date.UTC(year, month - 1, day));
  // Converta a data UTC para o horário local
  const localDate = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
  );
  return localDate;
}
