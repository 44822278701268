import api from '~/services/api';

const precosCache: any[] | null = null;

export class NumberFormat {
  public static fltRound(num: any, dp: number): any {
    if (arguments.length !== 2) throw new Error('2 arguments required');

    num = String(num);
    if (num.indexOf('e+') !== -1) {
      // Can't round numbers this large because their string representation
      // contains an exponent, like 9.99e+37
      throw new Error('num too large');
    }
    if (num.indexOf('.') === -1) {
      // Nothing to do
      return num;
    }

    const parts = num.split('.');
    const beforePoint = parts[0];
    let afterPoint = parts[1];
    const shouldRoundUp = afterPoint[dp] >= 5;
    let finalNumber;

    afterPoint = afterPoint.slice(0, dp);
    if (!shouldRoundUp) {
      finalNumber = `${beforePoint}.${afterPoint}`;
    } else if (/^9+$/.test(afterPoint)) {
      // If we need to round up a number like 1.9999, increment the integer
      // before the decimal point and discard the fractional part.
      finalNumber = Number(beforePoint) + 1;
    } else {
      // Starting from the last digit, increment digits until we find one
      // that is not 9, then stop
      let i = dp - 1;
      while (true) {
        if (afterPoint[i] === '9') {
          afterPoint = `${afterPoint.substr(0, i)}0${afterPoint.substr(i + 1)}`;
          i--;
        } else {
          afterPoint =
            afterPoint.substr(0, i) +
            (Number(afterPoint[i]) + 1) +
            afterPoint.substr(i + 1);
          break;
        }
      }

      finalNumber = `${beforePoint}.${afterPoint}`;
    }

    if (typeof finalNumber !== 'string') {
      // Remove trailing zeroes from fractional part before returning
      return parseFloat(String(finalNumber).replace(/0+$/, ''));
    }

    return finalNumber;
  }

  public static async AplicaRegraArredondamento(
    CodPreco: number,
    ValVenda: number,
    precos: any[],
  ): Promise<number> {
    let ValArred: number;

    ValVenda = this.fltRound(ValVenda, 2);

    if (ValVenda === 0) {
      ValArred = 0;
    }

    let preco: any = null;

    if (precos && Array.isArray(precos))
      [preco] = precos.filter((p) => p.cod_preco === CodPreco);

    if (!preco || !preco.flg_arredonda) return ValVenda;

    let ValInt = 0;
    let ValIntS1 = 0;
    let ValTrunc = 0;
    let ValFinal = 0;

    ValInt = ValVenda * 100;

    ValIntS1 = ValInt / 10;

    ValTrunc = Math.trunc(ValIntS1);

    ValFinal = this.fltRound(ValIntS1 - ValTrunc, 2);

    ValFinal *= 10;

    const { ArrayNumArred, ArrayTipoArred } = preco;

    for (let i = 0; i < ArrayNumArred.length; i++) {
      if (ValFinal === i && ValFinal !== ArrayNumArred[i]) {
        if (ArrayNumArred[i] === null) {
          return ValVenda;
        }
        if (ArrayTipoArred[i] === 0) {
          // pra baixo

          if (ArrayNumArred[i] === 0) {
            ValArred = (i + 10 - 10) / 100;
          } else {
            if (i > ArrayNumArred[i]) {
              ValArred = (i - ArrayNumArred[i]) / 100;
            } else {
              ValArred = (i + 10 - ArrayNumArred[i]) / 100;
            }

            ValVenda -= ValArred;
            ValArred *= -1;
          }
        } else if (ArrayNumArred[i] === 0) {
          ValArred = (10 - i) / 100;
        } else if (ArrayNumArred[i] < i) {
          ValArred = (ArrayNumArred[i] + 10 - i) / 100;
        } else {
          ValArred = (ArrayNumArred[i] - i) / 100;
          ValVenda += ValArred;
        }
      }
    }

    return ValVenda;
  }

  public static tranformAsCurrency(value: string): number {
    if (value.length < 6) {
      if (value.indexOf(',') > -1) {
        value = value.replace(',', '.');
      }
    } else {
      if (value.indexOf('.') > -1) {
        value = value.replace('.', '');
      }
      if (value.indexOf(',') > -1) {
        value = value.replace(',', '.');
      }
    }
    return Number(value);
  }
}
