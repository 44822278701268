import { moneyFormat } from '~/utils/functions';
import { Parcela } from './parcela.data';
import { ReceiptData } from './receipt.data';
import { jsPDF } from 'jspdf';

class ImpressaoRecibo {
  private receiptData: ReceiptData;

  private selectedParcelas: Parcela[];

  constructor(receiptData: ReceiptData, selectedParcelas: Parcela[]) {
    this.receiptData = receiptData;
    this.selectedParcelas = selectedParcelas;
  }

  static removeAccents(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  removeSpecialCharacters = async (): Promise<ReceiptData> => {
    const object: any = { ...this.receiptData };

    Object.entries(object).forEach((entry) => {
      const [key, value] = entry;
      if (typeof value === 'string' && String(key) !== 'null') {
        object[key as keyof ReceiptData] = ImpressaoRecibo.removeAccents(value);
      }
    });
    return object;
  };

  static breakLines(texto: string, maxChars: number): string {
    const palavras = texto.split(' ');
    let linhaAtual = '';
    let textoFormatado = '';

    palavras.forEach((palavra) => {
      if ((linhaAtual + palavra).length > maxChars) {
        textoFormatado += `${linhaAtual.trim()}\n`;
        linhaAtual = '';
      }
      linhaAtual += `${palavra} `;
    });

    if (linhaAtual.trim() !== '') {
      textoFormatado += linhaAtual.trim();
    }

    return textoFormatado;
  }

  static formatAddressLine(
    address: string,
    number: string,
    neighborhood: string,
  ): string {
    return `${address} N.: ${number} - ${neighborhood}`;
  }

  static formatDate(date: string, yearType = 'numeric'): string {
    const year: any = yearType;
    return new Date(date).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year,
    });
  }

  createReceiptHeader(): string {
    const storeCod = this.receiptData.cod_loja || '';
    const storeName = this.receiptData.des_loja || '';
    const address = ImpressaoRecibo.formatAddressLine(
      this.receiptData.des_rua_loja,
      this.receiptData.num_endereco_loja,
      this.receiptData.des_bairro_loja,
    );
    const phone = this.receiptData.num_telefone_loja || '';
    const zipCode = this.receiptData.num_cep_loja || '';
    const city = this.receiptData.des_cidade_loja || '';
    const state = this.receiptData.des_uf_loja || '';

    return (
      `(LJ:${storeCod})${storeName.substring(0, 39)}\n` +
      `${ImpressaoRecibo.breakLines(address, 40)}\n` +
      `FONE:${phone}\n` +
      `CEP ${zipCode} ${city} - ${state}\n` +
      `Emissao:${ImpressaoRecibo.formatDate(
        this.receiptData.dta_emissao || '',
      )}\n` +
      '------------------------------------------\n' +
      '             TITULOS QUITADOS             \n' +
      `           QUITADOS EM ${ImpressaoRecibo.formatDate(
        new Date().toISOString(),
      )}\n` +
      '------------------------------------------\n'
    );
  }

  createReceipClient(): string {
    const clienteNome = this.receiptData?.nome_pessoa || '';
    const ruaCliente = this.receiptData?.des_rua_cliente || '';
    const numEnderecoCliente = this.receiptData?.num_endereco_cliente || '';
    const bairroCliente = this.receiptData?.des_bairro_cliente || '';
    const clienteCidade = this.receiptData?.des_cidade_cliente || '';
    const clienteCep = this.receiptData?.num_cep_cliente || '';

    const endereco =
      ruaCliente && numEnderecoCliente && bairroCliente
        ? `${ruaCliente}, ${numEnderecoCliente} - ${bairroCliente}`
        : '';

    return (
      `${`CLIENTE: ${clienteNome}`.substring(0, 40)}\n` +
      `${ImpressaoRecibo.breakLines(endereco, 40)}\n` +
      `${clienteCidade} - ${clienteCep}\n` +
      'FONE: \n' +
      `__________________________________________\n`
    );
  }

  createReceiptParcelas(): string {
    let body = '  EMISSAO   VENCTO    VALOR  JUROS    V.LIQ\n';

    this.selectedParcelas.forEach((parcela) => {
      parcela.val_juros = parcela.val_juros || 0;
      const emissaoParcela = ImpressaoRecibo.formatDate(
        parcela.dta_entrada,
        '2-digit',
      );
      const vencimentoParcela = ImpressaoRecibo.formatDate(
        parcela.dta_vencimento,
        '2-digit',
      );
      const valorParcela = parcela.val_parcela.toFixed(2);
      const jurosParcela = parcela.val_juros.toFixed(2);
      const valorLiquidoParcela = (
        parcela.val_parcela + parcela.val_juros
      ).toFixed(2);

      // Cálculo das larguras para o alinhamento
      const larguraValorParcela =
        26 - emissaoParcela.length - vencimentoParcela.length - 2; // 26 é a posição desejada, -2 por causa dos espaços
      const larguraJurosParcela = 34 - 28; // 34 é a posição desejada para o final dos juros
      const larguraValorLiquidoParcela = 43 - 35; // 43 é a posição desejada para o final do valor líquido

      // Alinhamento dos valores monetários
      const valorParcelaFormatado =
        moneyFormat(valorParcela).padStart(larguraValorParcela);
      const jurosParcelaFormatado =
        moneyFormat(jurosParcela).padStart(larguraJurosParcela);
      const valorLiquidoParcelaFormatado = moneyFormat(
        valorLiquidoParcela,
      ).padStart(larguraValorLiquidoParcela);

      body += ` ${emissaoParcela} ${vencimentoParcela} ${valorParcelaFormatado} ${jurosParcelaFormatado} ${valorLiquidoParcelaFormatado}\n`;
    });

    return body;
  }

  createReceiptFooter(): string {
    const customerName = this.receiptData.nome_pessoa || '';
    const saldoDevedor = this.selectedParcelas.reduce(
      (acumulador, parcela) => acumulador + parcela.val_parcela,
      0,
    );
    const juros = this.selectedParcelas.reduce(
      (acumulador, parcela) => acumulador + parcela.val_juros,
      0,
    );
    const totalAmount = juros + saldoDevedor;
    return (
      '                   -------- ------ --------\n' +
      `SALDO DEVEDOR: ${moneyFormat(String(saldoDevedor)).padStart(
        12,
      )} ${moneyFormat(String(juros)).padStart(6)} ${moneyFormat(
        String(totalAmount),
      ).padStart(8)}\n` +
      '__________________________________________\n' +
      `${`       ${customerName}`.substring(0, 40)}\n`
    );
  }

  generateReceipt(): string {
    const header = this.createReceiptHeader();
    const client = this.createReceipClient();
    const Installment = this.createReceiptParcelas();
    const footer = this.createReceiptFooter();

    return header + client + Installment + footer;
  }

  gerarEbaixarPDF = (receipt: string): any => {
    const receiptInText = receipt;
    const linhas = receiptInText.split('\n');
    const numeroDeLinhas = linhas.length;

    const espacamentoEntreLinhas = 5; // Ajuste este valor conforme necessário
    const alturaPagina = numeroDeLinhas * espacamentoEntreLinhas + 20; // + 20mm de margem
    const larguraPagina = 80;

    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      // format: [4 * 25.4, 6 * 25.4], // Aproximadamente 4x6 polegadas
      format: [larguraPagina, alturaPagina],
    });

    doc.setFont('Courier'); // Define a fonte para Courier
    doc.setFontSize(8); // Define o tamanho da fonte
    doc.setTextColor(0, 0, 0); // Define a cor do texto para preto

    linhas.forEach((linha, index) => {
      doc.text(linha, 0, 10 + index * espacamentoEntreLinhas);
    });

    doc.save('recibo.pdf');
  };

  baixarRecibo = async (baixar: boolean): Promise<string> => {
    this.receiptData = await this.removeSpecialCharacters();
    const recibo = this.generateReceipt();

    if (baixar) {
      this.gerarEbaixarPDF(recibo);
    }

    return recibo;
  };
}

export default ImpressaoRecibo;
