import { pontuaCpfCnpj } from '~/utils/functions';
import { ReceiptData } from './receipt.data';

class ImpressaoPdvOnlie {
  private receiptData: ReceiptData;

  constructor(receiptData: ReceiptData) {
    this.receiptData = receiptData;
  }

  static breakAndCenterLines(
    texto: string,
    maxChars: number,
    lineLength: number,
  ): string {
    const palavras = texto.split(' ');
    let linhaAtual = '';
    let textoFormatado = '';

    palavras.forEach((palavra) => {
      if ((linhaAtual + palavra).length > maxChars) {
        const linhaCentralizada = ImpressaoPdvOnlie.centerText(
          linhaAtual.trim(),
          lineLength,
        );
        textoFormatado += `${linhaCentralizada}\n`;
        linhaAtual = '';
      }
      linhaAtual += `${palavra} `;
    });

    if (linhaAtual.trim() !== '') {
      const linhaCentralizada = ImpressaoPdvOnlie.centerText(
        linhaAtual.trim(),
        lineLength,
      );
      textoFormatado += linhaCentralizada;
    }

    return textoFormatado;
  }

  static formatAddressLine(
    address: string,
    number: number,
    neighborhood: string,
  ): string {
    return `${address} N.: ${number} - ${neighborhood}`;
  }
  static formatPhone(phone: string): string {
    const onlyNumbers = phone.replace(/\D/g, '');

    const firstGroup = onlyNumbers.slice(0, 2);
    const secondGroup = onlyNumbers.slice(2, 6);
    const thirdGroup = onlyNumbers.slice(6, 10);

    const formattedNumber = `( XX${firstGroup}) ${secondGroup}-${thirdGroup}`;
    return formattedNumber;
  }
  static formatDate(date: string, yearType = 'numeric'): string {
    const year: any = yearType;
    return new Date(date).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year,
    });
  }
  static centerText(text: string, lineLength: number): string {
    const spacesBefore = Math.max(
      0,
      Math.floor((lineLength - text.length) / 2),
    );
    const spacesAfter = Math.max(0, lineLength - text.length - spacesBefore);

    const centeredText =
      ' '.repeat(spacesBefore) + text + ' '.repeat(spacesAfter);

    return centeredText;
  }

  createReceiptHeader(): string {
    const storeName = ImpressaoPdvOnlie.centerText(
      this.receiptData.des_loja || '',
      45,
    );

    const storeFantasia = ImpressaoPdvOnlie.centerText(
      this.receiptData.des_fantasia || '',
      45,
    );

    const address = ImpressaoPdvOnlie.formatAddressLine(
      this.receiptData.des_logradouro,
      this.receiptData.num_endereco,
      this.receiptData.des_bairro,
    );
    const phone = this.receiptData.num_telefone || '';
    const zipCode = this.receiptData.num_cep || '';
    const city = this.receiptData.des_cidade || '';
    const state = this.receiptData.des_uf || '';
    const cnpj = this.receiptData.num_cnpj || '';
    const ie = this.receiptData.num_ie || '';
    const dtaMov = this.receiptData.dta_movimento || '';
    const hraMov = this.receiptData.hra_mov;
    const codOperador = this.receiptData.cod_operador || '';
    const desOperador = this.receiptData.des_operador || '';
    const numPdv = this.receiptData.num_pdv || '';
    const valMov = this.receiptData.val_mov || '0,00';

    const cepString = ImpressaoPdvOnlie.centerText(
      `CEP ${zipCode} ${city} - ${state}`,
      45,
    );
    const phoneString = ImpressaoPdvOnlie.centerText(
      `FONE: ${ImpressaoPdvOnlie.formatPhone(phone)}`,
      45,
    );
    const cnpjString = ImpressaoPdvOnlie.centerText(
      `CNPJ: ${pontuaCpfCnpj(cnpj)}`,
      45,
    );
    const ieString = ImpressaoPdvOnlie.centerText(`IE: ${ie}`, 45);

    return (
      `${storeFantasia}\n` +
      `${storeName}\n` +
      `${ImpressaoPdvOnlie.centerText(
        ImpressaoPdvOnlie.breakAndCenterLines(address, 40, 45),
        45,
      )}\n` +
      `${cepString}\n` +
      `${phoneString}\n` +
      `${cnpjString}\n` +
      `${ieString}\n` +
      `  ${dtaMov} ${hraMov}` +
      `\n` +
      `${ImpressaoPdvOnlie.centerText('NAO E DOCUMENTO FISCAL', 45)}\n` +
      `${ImpressaoPdvOnlie.centerText('COMPROVANTE NAO-FISCAL', 45)}\n\n` +
      `  Operador: ${codOperador} ${desOperador}  Caixa: ${numPdv}\n` +
      `${'_'.repeat(51)}\n` +
      `  Fundo de abertura:              ${valMov}\n` +
      `  TOTAL R$                        ${valMov}`
    );
  }

  generateReceipt(): string {
    const header = this.createReceiptHeader();
    // const client = this.createReceipClient();
    // const Installment = this.createReceiptParcelas();
    // const footer = this.createReceiptFooter();

    return header;
  }

  baixarRecibo = async (): Promise<string> => {
    const recibo = this.generateReceipt();

    return recibo;
  };
}

export default ImpressaoPdvOnlie;
