/* eslint-disable no-case-declarations */
import api from '~/services/api';
import { moneyFormat, pontuaCpfCnpj } from '~/utils/functions';

interface ReadingDateProps {
  leitura: {
    abertura: any[] | null;
    reforco: any[] | null;
    sangria: any[] | null;
    vendas: any[] | null;
  };
  loja: { cod_loja: number; cod_operador: number; num_pdv: number };
  saldo_final: any[];
}

class ImprimirLeituraCaixa {
  private readingDate: ReadingDateProps;
  private header: string;

  constructor(readingDate: ReadingDateProps) {
    this.readingDate = readingDate;
    this.header = '';
  }

  private static centerText(text: string, lineLength: number): string {
    const spaces = ' '.repeat(Math.max(0, (lineLength - text.length) / 2));
    return spaces + text + spaces;
  }

  private static formatCep(cep: string): string {
    return `${cep.slice(0, 5)}-${cep.slice(5)}`;
  }

  private static createHyphens(
    spaceCount: number,
    hyphenCount: number,
  ): string {
    const hyphens = '-'.repeat(hyphenCount);
    const spaces = ' '.repeat((spaceCount - hyphenCount) / 2);
    return spaces + hyphens + spaces;
  }

  private static padString(
    str: string,
    side: 'left' | 'right' | 'both',
    spaces: number,
  ): string {
    const padding = ' '.repeat(spaces);
    const lines = str.split('\n');

    const paddedLines = lines.map((line) => {
      switch (side) {
        case 'left':
          return padding + line;
        case 'right':
          return line + padding;
        case 'both':
          const leftPadding = ' '.repeat(spaces / 2);
          return leftPadding + line + leftPadding;
        default:
          throw new Error('Lado inválido. Use "left", "right" ou "both".');
      }
    });

    return paddedLines.join('\n');
  }

  private static createGrid(
    totalLength: number,
    numCols: number,
    ...labels: string[]
  ): string {
    if (numCols <= 0)
      throw new Error('O número de colunas deve ser maior que zero.');

    if (labels.length !== numCols)
      throw new Error(
        'A quantidade de labels deve corresponder ao número de colunas.',
      );

    const totalLabelsLength = labels.reduce(
      (sum, label) => sum + label.length,
      0,
    );
    const spaceBetweenLabels = totalLength - totalLabelsLength;

    if (spaceBetweenLabels < numCols - 1) return labels.join(' ');

    const spacePerGap = Math.floor(spaceBetweenLabels / (numCols - 1));
    const remainingSpace = spaceBetweenLabels % (numCols - 1);

    return labels
      .map((label, index) => {
        const padding =
          index < labels.length - 1
            ? ' '.repeat(spacePerGap + (index < remainingSpace ? 1 : 0))
            : '';
        return ImprimirLeituraCaixa.padString(label, 'left', 2) + padding;
      })
      .join('');
  }

  private async createHeader(): Promise<string> {
    const { readingDate } = this;
    const { cod_loja, cod_operador, num_pdv } = readingDate.loja;

    const { data } = await api.get(
      `/busca-loja/${cod_loja}/${cod_operador}/${num_pdv}`,
    );

    if (!data) return '';

    const razaoSocial = data.des_loja;
    const cnpj = `CNPJ: ${pontuaCpfCnpj(data?.num_cnpj ?? '')}`;
    const cepEuf = `${ImprimirLeituraCaixa.formatCep(data?.num_cep ?? '')} ${
      data.des_cidade
    }/${data.des_uf}`;

    const lines: string[] = [
      data.des_fantasia || '',
      razaoSocial || '',
      cnpj || '',
      data.des_logradouro || '',
      `BAIRRO: ${data.des_bairro}` || '',
      cepEuf || '',
    ];

    return lines
      .map((line) => ImprimirLeituraCaixa.centerText(line, 45))
      .join('\n');
  }

  private static processData(data: any[]): any[] {
    return data.map((item) => {
      return Object.entries(item).reduce((acc, [key, value]) => {
        if (key === 'cod_finalizadora') return acc;
        if (key.startsWith('val_')) acc.value = value;
        else acc[key] = value;
        return acc;
      }, {} as any);
    });
  }

  private static buildEmptySection(name: string, hyphens: string): string {
    const formattedTotal = `R$ ${moneyFormat('0')}`;
    const totalValue =
      name.toUpperCase() === 'SANGRIA' ? `${formattedTotal}-` : formattedTotal;

    const totalGrid = ImprimirLeituraCaixa.createGrid(
      40,
      2,
      'TOTAL',
      totalValue,
    );

    const sectionName = ImprimirLeituraCaixa.padString(name, 'left', 2);

    return `\n${sectionName}\n${hyphens}\n${totalGrid}\n${hyphens}`;
  }

  private static buildGrids(
    data: any[],
    hyphens: string,
    name: string,
  ): string {
    const formatValue = (item: any): string => {
      const formattedValue = `R$ ${moneyFormat(item.value.toFixed(2))}`;
      return name.toUpperCase() === 'SANGRIA'
        ? `${formattedValue}-`
        : formattedValue;
    };

    return data
      .map((item, idx) => {
        const grid = ImprimirLeituraCaixa.createGrid(
          40,
          2,
          item.des_finalizadora,
          formatValue(item),
        );
        return idx === data.length - 1 ? `${grid}\n${hyphens}` : grid;
      })
      .join('\n');
  }

  private static buildTotalSection(
    name: string,
    data: any[],
    hyphens: string,
  ): string {
    if (name.toLowerCase() === 'abertura') return '';

    const totalValue = data.reduce((sum, item) => sum + item.value, 0);
    const formattedTotal = `R$ ${moneyFormat(totalValue.toFixed(2))}`;
    const totalWithHyphen =
      name.toUpperCase() === 'SANGRIA' ? `${formattedTotal}-` : formattedTotal;

    const totalGrid = ImprimirLeituraCaixa.createGrid(
      40,
      2,
      'TOTAL',
      totalWithHyphen,
    );

    return `\n${totalGrid}\n${hyphens}`;
  }

  private static createSection(name: string, data: any[] | null): string {
    const hyphens = ImprimirLeituraCaixa.createHyphens(45, 43);
    const upperName = name.toUpperCase();

    if (!data) return this.buildEmptySection(upperName, hyphens);

    const processedData = this.processData(data);
    const grids = this.buildGrids(processedData, hyphens, upperName);
    const totalSection = this.buildTotalSection(
      upperName,
      processedData,
      hyphens,
    );

    return `\n${ImprimirLeituraCaixa.padString(
      upperName,
      'left',
      2,
    )}\n${grids}${totalSection}`;
  }

  private createBody(): string {
    const { leitura, saldo_final } = this.readingDate;
    const hyphens = ImprimirLeituraCaixa.createHyphens(45, 43);

    const sections = [
      { name: 'Abertura', data: leitura.abertura },
      { name: 'Vendas', data: leitura.vendas },
      { name: 'Suprimento', data: leitura.reforco },
      { name: 'Sangria', data: leitura.sangria },
      { name: 'Saldo Final', data: saldo_final },
    ];

    const formattedSections = sections
      .map(({ name, data }) => ImprimirLeituraCaixa.createSection(name, data))
      .join('\n');

    return `\n\n${hyphens}${formattedSections}`;
  }

  private async generate(): Promise<string> {
    const header = await this.createHeader();
    const body = this.createBody();
    return header + body;
  }

  public async create(): Promise<string> {
    const leitura = await this.generate();
    return leitura;
  }
}

export default ImprimirLeituraCaixa;
